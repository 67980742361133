import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    background-color: ${variables.dpc_backgroundWhite};
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
    
    .sidebar-container {
        position: relative;
        background-color: ${variables.dpc_backgroundBlack};
        min-width: 9em;
        min-height: 100vh;
        box-shadow: 0px 3px 16px #00000029;
        border: 1px solid ${variables.dpc_backgroundBlack};
        z-index: 10;

        .navigation {
            .panel-sidebar-link {
                &.application {
                    .icon {
                        min-width: 2em;
                        max-width: 2.5em;
                        margin-left: .45em;
                        svg {
                            path { 
                                fill: none !important; 
                                stroke-width: 1.25em; 
                            }
                        }
                    }

                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    stroke: ${variables.dpc_iconWhite} !important; 
                                }
                            }
                        }
                    }
                }

                &.invite { 
                    .icon {
                        svg {
                            path { 
                                fill: none !important; 
                                stroke-width: 1.25em; 
                            }
                        }
                    }

                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    stroke: ${variables.dpc_iconWhite} !important; 
                                }
                            }
                        }
                    }
                }

                &.fitadept-tv { 
                    &:hover {
                        .icon {
                            svg {
                                path { 
                                    fill: #FFFFFF !important; 
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .layout-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        padding: 0;
        width: 100% !important;
    
        margin: 0;
    }

    .page-title {
        font-size: 3em;
        color: ${variables.dpc_fontDark};
        font-weight: 600;
        text-align: center;
        line-height: 120%;
    }

    .options {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 2em;

        .option-wrapper {
            width: 31%;
            min-height: 32em;
            height: 100%;
        
            .option {
                min-height: 32em;
                height: 32em;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-align: center;
                color: ${variables.dpc_fontWhite};
                border-radius: .3em;
                position: relative;
                padding: 2em;
                transition: .2s all ease-in-out;
                cursor: pointer;

                .option-bg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    transition: .2s all ease-in-out;
                    box-shadow: 0 0 0 1em rgba(0, 0, 0 .2);
                    background-size: cover;
                    background-position: center;
                }

                .option-bg-mask {
                    position: absolute;
                    z-index: 3;
                    height: 100%;
                    width: 100%;
                    content: "";
                    top: 0;
                    left: 0;
                    display: block;
                }

                .option-content {
                    position: relative;
                    z-index: 3;
                    padding-top: 20%;

                    .option-title {
                        font-size: 2em;
                        font-weight: 900;
                        color: white;
                    }

                    .option-features {
                        display: block;
                        margin-top: 1em;

                        .option-feature {
                            line-height: 150%;
                            color: white;
                        }
                    }
                }

                &:hover {
                    transform: scale(1.05);
                }
            }

            .options-description {
                width: 100%;
                margin-top: 5em;
                text-align: left;
                font-size: 1em;

                .description-title {
                    display: block;
                    font-weight: 600;
                    color: white;
                    padding-bottom: 1em;
                }

                .description-feature {
                    display: block;
                    margin-bottom: .3em;
                    color: white;

                    .anchor {
                        color: #DF3846;
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.desktopXS}) {
        .options {
            .option-wrapper {
                .option {
                    .option-content {
                        .option-title {
                            .option-features {
                                .options-description {
                                        margin-top: 3em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.desktopS}) {
        .options {
            .option-wrapper {
                .option {
                    .option-content {
                        .option-title {
                            font-size: 1.6em;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        .options {
            flex-direction: column;
            .option-wrapper {
                width: 100%;
                margin-bottom: 2em;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .option {
                    min-height: 10em;
                    width: 100%;
                    padding: 1em;

                    &:hover {
                        transform: scale(1);


                        .option-bg {
                            background: #111111;
                        }
                    }

                    &.active {
                        transform: scale(1);

                        .option-bg {
                            background: rgb(255,0,49);
                            background-image: linear-gradient(to left,#DA3143,#FF6254);
                        }
                    }
                }

                .options-description {
                    width: 50%;
                    padding-left: 2em;
                }
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .options {
            .option-wrapper {
                margin-bottom: 4em;
                flex-direction: column;
                
                .option {
                    width: 100%;
                    margin-bottom: 1em;
                }
                
                .options-description {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
    }

    @media (max-width: ${variables.tabletL}) {
        flex-direction: column;

        .sidebar-container {
            width: 100vw;
            min-height: auto;

            .panel-sidebar {
                justify-content: flex-end;
            }
        }

        .options {
            margin: 0 2em;
        }
    }
`;

