import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    PUBLIC_SHOP_STUDIO, 
    PUBLIC_SHOP_ONLINE, 
    PUBLIC_SHOP_MASSAGE,
    ADEPT_DASHBOARD,
    ADEPT_EVENTS,
    ADEPT_DATA,
    ADEPT_USER_OFFERS,
    ADEPT_FINANCES,
    ADEPT_CONTACTS,
    PUBLIC_MOBILE_APP,
    PUBLIC_SHOP,
} from 'Consts/routes';
import Head from 'Components/core/Head';
import { USER_ROLE_ADEPT } from 'Consts/userRoles';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Sidebar from 'Components/layout/panel/Sidebar';

export default class PublicShop extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        authToken: PropTypes.string,
        profile: PropTypes.object,
        actions: PropTypes.shape({
            getProfile: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        authToken: null,
        profile: null,
    };
    state = {};

    componentDidMount = () => {
        const { authToken, actions } = this.props;

        if (authToken) {
            actions.getProfile();
        }
    }
    render() {
        const { history, profile, location } = this.props;
        const { search } = location;
        
        const queryObject = parseQueryToObject(search, true);
        
        return (
            <StyledComponent
                className="public-shop"
                styles={require('./styles')}
            >
                <Head title="Sklep" />
                {profile && profile.role === USER_ROLE_ADEPT && (
                    <div className="sidebar-container">
                        <Sidebar
                            mainPath={ADEPT_DASHBOARD.path}
                            mainPathVisible={false}
                            navigation={[{
                                key: 'trainings',
                                title: 'Wydarzenia',
                                link: ADEPT_EVENTS.path,
                                icon: require('Theme/images/icons/training.svg'),
                            }, {
                                key: 'medical-cards',
                                title: 'Kartoteka',
                                link: ADEPT_DATA.path,
                                icon: require('Theme/images/icons/health-data.svg'),
                            }, {
                                key: 'user-offers',
                                title: 'Zamówienia',
                                link: ADEPT_USER_OFFERS.path,
                                icon: require('Theme/images/icons/star.svg'),
                            }, {
                                key: 'finances',
                                title: 'Finanse',
                                link: ADEPT_FINANCES.path,
                                icon: require('Theme/images/icons/finance.svg'),
                            }, {
                                key: 'contacts',
                                title: 'Kontakty',
                                link: ADEPT_CONTACTS.path,
                                icon: require('Theme/images/icons/user.svg'),
                            }, {
                                key: 'application',
                                title: 'Aplikacja FitAdept',
                                link: PUBLIC_MOBILE_APP.path,
                                icon: require('Theme/images/icons/mobile-solid.svg'),
                            }, {
                                key: 'fitadept-tv',
                                title: 'FitAdept TV',
                                link: profile.accessCode
                                    ? `${process.env.FATV_APP_URL}/klienci-korporacyjni?accessCode=${profile.accessCode}&email=${profile.email}&name=${profile.name}&surname=${profile.surname}`
                                    : process.env.FATV_APP_URL,
                                icon: require('Theme/images/icons/tv-solid.svg'),
                                isLinkOutgoing: true,
                            }, {
                                key: 'invite',
                                title: 'Zaproś przyjaciół',
                                link: 'https://fitadept.com/program-polecen',
                                icon: require('Theme/images/icons/user-friends.svg'),
                                isLinkOutgoing: true,
                            }, {
                                key: 'sklep',
                                title: 'Sklep',
                                icon: require('Theme/images/icons/wallet.svg'),
                                link: PUBLIC_SHOP.path,
                            }, {
                                key: 'logout',
                                title: 'Wyloguj',
                                icon: require('Theme/images/icons/logout.svg'),
                                onClick: () => actions.logout()
                                    .then(() => {
                                        history.push(PUBLIC_USER_LOGIN.path);
                                    }),
                            }]}
                        />
                    </div>
                )}
                <div className="shop-page-content">
                    <h1 className="page-title">
                        Wybierz usługę
                    </h1>
                    <div className="options">
                        <div className="option-wrapper">
                            <div 
                                className="option"
                                onClick={() => history.push(
                                    withVariables(
                                        PUBLIC_SHOP_STUDIO.path,
                                        {},
                                        { comment: queryObject.comment }
                                    )
                                )}
                                
                            >
                                <span
                                    className="option-bg" 
                                    style={{
                                        backgroundImage: `url(${require('Theme/images/shop/studio.png')})`,
                                    }}
                                />
                                <div 
                                    className="option-bg-mask"
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)'
                                    }}
                                />
                                <div className="option-content">
                                    <h3 className="option-title">
                                        TRENINGI I REHABILITACJA <br />W STUDIU TRENINGOWYM
                                    </h3>
                                    <div className="option-features">
                                        <span className="option-feature">
                                            Treningi personalne i rehabilitacja w kameralnym studiu treningowym FitAdept w Warszawie.
                                        </span>
                                        <div className="options-description">
                                            <span className="description-title">
                                                W każdym pakiecie otrzymujesz:
                                            </span>
                                            <span className="description-feature">
                                                - indywidualny plan treningowy
                                            </span>
                                            <span className="description-feature">
                                                - kontrola wyników
                                            </span>
                                            <span className="description-feature">
                                                - dostęp do kameralnego studia treningowego i rehabilitacji w centrum Warszawy (Pl. Zawiszy)
                                            </span>
                                            <span className="description-feature">
                                                - dostęp do aplikacji mobilnej FitAdept oraz nagrań treningowych <a className="anchor" href="https://fitadept.tv/">fitadept.tv</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="option-wrapper">
                            <div 
                                className="option"
                                onClick={() => history.push(
                                    withVariables(
                                        PUBLIC_SHOP_ONLINE.path,
                                        {},
                                        { comment: queryObject.comment }
                                    )
                                )}
                            >
                                <span 
                                    className="option-bg"
                                    style={{
                                        backgroundImage: `url(${require('Theme/images/shop/online.png')})`,
                                    }}
                                />

                                <div 
                                    className="option-bg-mask"
                                    style={{
                                        backgroundColor: 'rgba(252, 43, 27, 0.6)'
                                    }}
                                />

                                <div className="option-content">
                                    <h3 className="option-title">
                                        TRENINGI I REHABILITACJA ONLINE
                                    </h3>
                                    <div className="option-features">
                                        <span className="option-feature">
                                            Wideo-treningi personalne i wideo-rehabilitacja online (1:1 z certyfikowanym trenerem lub fizjoterapetą)
                                        </span>
                                        <div className="options-description">
                                            <span className="description-title">
                                                W każdym pakiecie otrzymujesz:
                                            </span>
                                            <span className="description-feature">
                                                - indywidualny plan treningowy
                                            </span>
                                            <span className="description-feature">
                                                - kontrola wyników
                                            </span>
                                            <span className="description-feature">
                                                - dostęp do aplikacji mobilnej FitAdept oraz nagrań treningowych <a className="anchor" href="https://fitadept.tv/" style={{ color: 'black'}}>fitadept.tv</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="option-wrapper">
                            <div 
                                className="option"
                                onClick={() => history.push(
                                    withVariables(
                                        PUBLIC_SHOP_MASSAGE.path,
                                        {},
                                        { comment: queryObject.comment }
                                    )
                                )}
                            >
                                <span 
                                    className="option-bg"
                                    style={{
                                        backgroundImage: `url(${require('Theme/images/shop/massage.jpg')})`,
                                    }}
                                />

                                <div 
                                    className="option-bg-mask"
                                    style={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)'
                                    }}
                                />

                                <div className="option-content">
                                    <h3 className="option-title">
                                        MASAŻE
                                    </h3>
                                    <div className="option-features">
                                        <span className="option-feature">
                                            Masaże lecznicze i relaksacyjne w gabinecie masażu FitAdept w Warszawie.
                                        </span>
                                        <div className="options-description">
                                            <span className="description-title">
                                                W każdym pakiecie otrzymujesz:
                                            </span>
                                            <span className="description-feature">
                                                - masaże wykonywane przez doświadczone fizjoterapeutki
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
